function easeInOutQuad(
    timing: number,
    start: number,
    change: number,
    duration: number,
): number {
    timing /= duration / 2;

    if (timing < 1) {
        return change / 2 * timing * timing + start;
    }

    return -change / 2 * ((--timing) * (timing - 2) - 1) + start;
}

export function smoothScroll(
    to: number,
    property: 'top' | 'left' = 'top',
    duration = 500,
): void {
    const start = (property === 'top' ? window.scrollY : window.scrollX);
    const change = to < start ? (start * -1) + to : to - start;
    const startDate = new Date().getTime();

    const animateScroll = () => {
        const currentDate = new Date().getTime();
        const currentTime = currentDate - startDate;

        const newPosition = easeInOutQuad(currentTime, start, change, duration);

        if (property === 'top') {
            window.scrollTo({ top: newPosition });
        } else {
            window.scrollTo({ left: newPosition });
        }

        if (currentTime < duration) {
            window.requestAnimationFrame(animateScroll);
        } else {
            // Finished scrolling -- Make sure we go to final position
            if (property === 'top') {
                window.scrollTo({ top: to, behavior: 'smooth' });
            } else {
                window.scrollTo({ left: to, behavior: 'smooth' });
            }
        }
    };

    animateScroll();
}

export function scrollElemToMiddle(el: HTMLElement, offset?: number) {
    const currentScrollPosition = window.scrollY;
    const targetPosition = (el.getBoundingClientRect().top - (window.outerHeight / 2)) + currentScrollPosition;

    smoothScroll(targetPosition + (offset || 0));
}

export function scrollElemToTop(el: HTMLElement, offset?: number) {
    const currentScrollPosition = window.scrollY;
    const targetPosition = el.getBoundingClientRect().top + currentScrollPosition;

    smoothScroll(targetPosition + (offset || 0));
}

const scrollPosition = 0;

export const lockBody = function() {
    const rootNodes = document.querySelectorAll('html, body') as NodeListOf<HTMLHtmlElement | HTMLBodyElement>;
    rootNodes.forEach(node => {
        node.classList.add('no-scrolling');
        node.style.overflow = 'hidden';
        node.style.position = 'fixed';
        node.style.top = `-${scrollPosition}px`;
        node.style.width = '100%';
    });
};


export const unlockBody = function(scrollToTop = false) {
    const rootNodes = document.querySelectorAll('html, body') as NodeListOf<HTMLHtmlElement | HTMLBodyElement>;
    rootNodes.forEach(node => {
        node.classList.remove('no-scrolling');
        node.style.removeProperty('overflow');
        node.style.removeProperty('position');
        node.style.removeProperty('top');
        node.style.removeProperty('width');
    });

    if (scrollToTop) {
        window.scrollTo(0, document.documentElement.scrollTop);
    }
};

export function sticky(stickyElement: HTMLElement, container: HTMLElement, topOffset = 0) {
    const originalOffset = stickyElement.getBoundingClientRect().top + window.scrollY;
    const containerOffset = container.getBoundingClientRect().top + window.scrollY;
    const containerHeight = container.offsetHeight;

    function onScroll() {
        const stickyRect = stickyElement.getBoundingClientRect();
        const containerBottom = containerOffset + containerHeight;

        if (window.scrollY > originalOffset - topOffset && window.scrollY < containerBottom - stickyRect.height) {
            stickyElement.style.position = 'fixed';
            stickyElement.style.top = `${topOffset}px`;
            stickyElement.style.width = '100%';
        } else {
            stickyElement.removeAttribute('style');
        }
    }

    window.addEventListener('scroll', onScroll);
}
